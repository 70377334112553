import { hideCursor, showCursor } from '@darkobits/react-kit/hide-cursor'
import fScreen from 'fscreen'
import mouseTrap from 'mousetrap'

import { FULLSCREEN_CURSOR_HIDE_DELAY } from 'etc/constants'

/**
 * @private
 *
 * Tracks the current mouse hide timeout.
 */
let mouseIdleTimeout: NodeJS.Timeout

/**
 * @private
 *
 * Hides the mouse cursor after it becomes idle in fullscreen mode.
 */
function handleFullScreenMouseMove() {
  clearTimeout(mouseIdleTimeout)
  showCursor()

  mouseIdleTimeout = setTimeout(() => {
    hideCursor()
  }, FULLSCREEN_CURSOR_HIDE_DELAY)
}

/**
 * Handles additional side-effects that should occur when entering / leaving
 * full screen mode.
 */
fScreen.addEventListener('fullscreenchange', () => {
  if (document.fullscreenElement) {
    document.addEventListener('mousemove', handleFullScreenMouseMove)
    return
  }

  document.removeEventListener('mousemove', handleFullScreenMouseMove)
  clearTimeout(mouseIdleTimeout)
  showCursor()
})

/**
 * Toggles full-screen mode when the user presses the 'F' key. Note: Full screen
 * mode can also be disabled if the user presses the 'Esc' key.
 */
mouseTrap.bind('f', () => {
  // Device / browser does not support full screen mode.
  if (!fScreen.fullscreenEnabled) return

  const isFullScreen = document.fullscreenElement

  if (isFullScreen) {
    fScreen.exitFullscreen()
  } else {
    const htmlEl = document.querySelector('html')
    if (htmlEl) fScreen.requestFullscreen(htmlEl)
  }
})