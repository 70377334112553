import type React from 'react'

/**
 * Return type of `createGradientPreset`.
 */
export interface GradientPreset {
  /**
   * Name of the preset. Used for debugging in development.
   */
  // name: string;

  /**
   * CSS properties for the preset.
   */
  style: React.CSSProperties
}

export type PresetGenerator = (...args: Array<any>) => GradientPreset;
export type StyleGenerator = (...args: Array<any>) => GradientPreset['style'];

/**
 * @private
 *
 * Accepts a name and style generation function and returns a function that
 * accepts an arbitrary number of arguments and returns a GradientPreset.
 */
function createGradientPreset(generateStyles: StyleGenerator): PresetGenerator {
  return (...args: Array<any>) => {
    return {
      style: generateStyles(...args)
    }
  }
}

/**
 * Map of gradient preset names to generator functions. Each generator function
 * accepts an angle and returns an object of CSS properties.
 */
// export const gradients = new Map<string, PresetGenerator>();
export const gradients = {
  // https://uigradients.com/#Frost - #004E92, #1D288B
  'Frost': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(208deg 100% 29%) 0%,
      hsl(221deg 82% 31%) 50%,
      hsl(234deg 65% 33%) 100%
    )`,
    opacity: 0.7
  })),

  // https://uigradients.com/#CrystalClear - #155799, #165938
  'Crystal Clear': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(210deg 96% 28%) 0%,
      hsl(170deg 80% 28%) 100%
    )`,
    filter: 'saturate(1) brightness(0.72)',
    opacity: 1
  })),
  // https://uigradients.com/#Moss - #134E5E, #71B280
  'Moss': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(193deg 66% 22%) 0%,
      hsl(163deg 49% 32%) 50%,
      hsl(160deg 55% 36%) 100%
    )`,
    // filter: 'brightness(1)',
    opacity: 0.62
  })),
  // https://uigradients.com/#TaranTado - #CC4C29, #23074D
  'Taran Tado': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(319deg 75% 28%) 0%,
      hsl(264deg 83% 18%) 100%
    )`,
    // filter: 'saturate(1) brightness(0.7)',
    opacity: 0.6
  })),
  // https://uigradients.com/#CosmicFusion - #333399, #660052
  'Cosmic Fusion': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(240deg 60% 32%) 0%,
      hsl(312deg 60% 32%) 100%
    )`,
    opacity: 1
  })),
  // https://uigradients.com/#PurpleLove - #6D1F87, #801B3B
  'Purple Love': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(285deg 63% 22%) 0%,
      hsl(313deg 64% 26%) 50%,
      hsl(341deg 55% 32%) 100%
    )`,
    opacity: 1
  })),
  // https://uigradients.com/#Mild - #67B26F, #4CA2CD
  'Mild': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(163deg 50% 32%) 0%,
      hsl(200deg 70% 36%) 100%
    )`,
    opacity: 0.8
  })),
  // https://uigradients.com/#Mauve - #42275A, #734B6D
  'Mauve': createGradientPreset((angle: number) => ({
    background: `linear-gradient(
      ${angle}deg,
      hsl(272deg 50% 34%) 0%,
      hsl(290deg 40% 36%) 50%,
      hsl(309deg 38% 38%) 100%
    )`,
    opacity: 0.5
  }))
}

export type GradientName = keyof typeof gradients;

// https://uigradients.com/#Frost - #004E92, #1D288B
// gradients.set('Frost', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(208deg 100% 29%) 0%,
//     hsl(221deg 82% 31%) 50%,
//     hsl(234deg 65% 33%) 100%
//   )`,
//   opacity: 0.7
// })));

// https://uigradients.com/#CrystalClear - #155799, #165938
// gradients.set('Crystal Clear', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(210deg 96% 28%) 0%,
//     hsl(170deg 80% 28%) 100%
//   )`,
//   filter: 'saturate(1) brightness(0.72)',
//   opacity: 1
// })));

// https://uigradients.com/#Moss - #134E5E, #71B280
// gradients.set('Moss', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(193deg 66% 22%) 0%,
//     hsl(163deg 49% 32%) 70%,
//     hsl(160deg 55% 40%) 100%
//   )`,
//   // filter: 'brightness(1)',
//   opacity: 0.62
// })));

// https://uigradients.com/#TaranTado - #CC4C29, #23074D
// gradients.set('Taran Tado', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(319deg 75% 28%) 0%,
//     hsl(264deg 83% 18%) 100%
//   )`,
//   // filter: 'saturate(1) brightness(0.7)',
//   opacity: 0.6
// })));

// https://uigradients.com/#CosmicFusion - #333399, #660052
// gradients.set('Cosmic Fusion', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(240deg 60% 32%) 0%,
//     hsl(312deg 60% 24%) 100%
//   )`,
//   opacity: 0.9
// })));

// https://uigradients.com/#PurpleLove - #6D1F87, #801B3B
// gradients.set('Purple Love', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(285deg 63% 23%) 0%,
//     hsl(313deg 64% 32%) 50%,
//     hsl(341deg 65% 40%) 100%
//   )`,
//   opacity: 0.6
// })));

// https://uigradients.com/#Mild - #67B26F, #4CA2CD
// hsl(126deg 35% 28%) 0%,
// gradients.set('Mild', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(163deg 50% 32%) 0%,
//     hsl(200deg 70% 36%) 100%
//   )`,
//   opacity: 0.8
// })));

// https://uigradients.com/#Mauve - #42275A, #734B6D
// gradients.set('Mauve', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(272deg 50% 34%) 0%,
//     hsl(290deg 40% 36%) 50%,
//     hsl(309deg 38% 38%) 100%
//   )`,
//   opacity: 0.5
// })));

// ----- Unused Gradients ------------------------------------------------------

// https://uigradients.com/#Mello - #BF3326, #8832AD
// too similar to Taran Tado, at least with current tuning
// gradients.set('Mello', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(320deg 68% 56%) 0%,
//     hsl(323deg 61% 44%) 50%,
//     hsl(282deg 55% 38%) 100%
//   )`,
//   opacity: 0.4
// })));

// https://uigradients.com/#WitchingHour - #B0122D, #240B36
// gradients.set('Witching Hour', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(350deg 50% 38%) 0%,
//     hsl(313deg 60% 25%) 50%,
//     hsl(275deg 65% 13%) 100%
//   )`,
//   filter: 'saturate(0.8)',
//   opacity: 0.8
// })));

// https://uigradients.com/#Relay - #3A1C71, #D76D77, #FFAF7B
// gradients.set('Relay', createGradientPreset((angle: number) => ({
//   background: `linear-gradient(
//     ${angle}deg,
//     hsl(261deg 60% 28%) 0%,
//     hsl(323deg 58% 52%) 45%,
//     hsl(4deg 60% 66%) 90%,
//     hsl(24deg 50% 60%) 100%
//   )`,
//   opacity: 0.54
// })));