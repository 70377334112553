import { twMerge } from 'tailwind-merge'

import classes from './IconSection.css'

import Envelope from '~icons/ph/envelope-thin'
import GitHub from '~icons/ph/github-logo-thin'
import LinkedIn from '~icons/ph/linkedin-logo-thin'

export default function IconSection() {
  const linkClassNames = twMerge(
    'flex align-center justify-center p-0 m-0 opacity-80 no-underline',
    classes.a
  )

  return (
    <section
      className={twMerge(
        'flex justify-center h-auto pointer-events-auto',
        classes.iconSection
      )}
    >
      <a
        className={linkClassNames}
        href="https://github.com/darkobits"
        title="GitHub"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="link-github"
      >
        <GitHub className="w-full h-full scale-95" />
      </a>
      <a
        className={linkClassNames}
        href="https://www.linkedin.com/in/joshua--martin/"
        title="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="link-linkedin"
      >
        <LinkedIn className="w-full h-full" />
      </a>
      <a
        className={linkClassNames}
        href="mailto:hello@joshua.dev"
        title="E-Mail"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="link-email"
      >
        <Envelope
          className="w-full h-full opacity-95 scale-[1.26] stroke-1"
          style={{ stroke: '#092F4DB0' }}
        />
      </a>
    </section>
  )
}