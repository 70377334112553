/**
 * Utilities for working with random numbers.
 */
export const random = {
  /**
   * Returns a random floating point number between `min` and `max` (inclusive).
   *
   * @default max 1, min 0
   */
  float: (max = 1, min = 0) => Math.random() * (max - min) + min,

  /**
   * Returns a random integer between `min` and `max` (inclusive).
   *
   * @default max 1, min 0
   */
  number: (max = 1, min = 0) => Math.floor(random.float(max, min)),

  /**
   * Returns a random index from the provided array.
   */
  arrayIndex: (arr: Array<any>) => random.number(arr.length - 1),

  /**
   * Returns a random element from the provided array.
  */
  arrayElement: <A extends Array<any>>(arr: A): A extends Array<infer U> ? U : never => arr[random.arrayIndex(arr)]
}