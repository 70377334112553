import React from 'react'
import { useAsyncEffect } from 'use-async-effect'

import ServiceWorkerContext from 'contexts/ServiceWorker'
import store from 'lib/storage'

export default function ReloadPrompt() {
  const [showReloadPrompt, setShowReloadPrompt] = React.useState(false)

  const {
    needRefresh,
    setNeedRefresh,
    updateServiceWorker
  } = React.useContext(ServiceWorkerContext)

  useAsyncEffect(async isMounted => {
    const showReloadPrompt = await store.getItem<boolean>('showReloadPrompt')

    if (showReloadPrompt === null) {
      void store.setItem<boolean>('showReloadPrompt', false)
      return
    }

    if (!isMounted()) return

    setShowReloadPrompt(showReloadPrompt)
  }, [])

  if (showReloadPrompt && needRefresh) return (
    <div
      className="
        fixed bottom-4 right-4
        flex flex-row items-center gap-2
        py-3 pl-4 pr-3
        min-w-60
        bg-slate-950/60
        text-slate-300 text-sm
        border border-slate-700 rounded-md
        shadow-xl
        z-50
      "
    >
      <span className="mr-2">New version available.</span>
      <button
        type="button"
        className="
          bg-slate-900/30
          hover:bg-slate-900
          border border-slate-700
          py-1 px-2
          rounded-md
          text-sm
        "
        onClick={() => void updateServiceWorker()}
      >
        Reload
      </button>
      <button
        type="button"
        className="
          bg-slate-900/30
          hover:bg-slate-900
          border border-slate-700
          py-1 px-2
          rounded-md
          text-sm
        "
        onClick={() => {
          setNeedRefresh(false)
          setShowReloadPrompt(false)
        }}
      >
        Cancel
      </button>
    </div>
  )
}