import { getPlatformDetails } from '@darkobits/react-kit/platform'
import React from 'react'

import { random } from 'lib/utils'

const platformDetails = getPlatformDetails()
const isSafari = platformDetails.browser.name === 'Safari'

export interface NoiseProps {
  type?: React.SVGAttributes<SVGFETurbulenceElement>['type']
  baseFrequency?: React.SVGAttributes<SVGFETurbulenceElement>['baseFrequency']
  numOctaves?: React.SVGAttributes<SVGFETurbulenceElement>['numOctaves']
  density?: number
  fps?: number
  style?: React.CSSProperties
  className?: string
}

/**
 * Generates an SVG containing noise. The noise can be tuned using various
 * parameters.
 *
 * See: https://css-tricks.com/grainy-gradients/
 */
export function Noise(props: NoiseProps = {}) {
  const svgRef = React.createRef<SVGSVGElement>()
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [baseFrequencyJitter, setBaseFrequencyJitter] = React.useState(random.float(0.001, 0.01))

  const type = props.type ?? 'fractalNoise'
  const baseFrequency = Number(props.baseFrequency ?? 0.65) + baseFrequencyJitter
  const numOctaves = props.numOctaves ?? 4
  const density = props.density ?? 4
  const fps = props.fps ?? 0
  const finalWidth = width * window.devicePixelRatio * density
  const finalHeight = height * window.devicePixelRatio * density

  /**
   * [Effect] Uses a ResizeObserver to update width and height and regenerate
   * noise.
   */
  React.useEffect(() => {
    const el = svgRef.current
    if (!el) return

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        setWidth(width)
        setHeight(height)
      }
    })

    resizeObserver.observe(el)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  /**
   * [Effect] If `fps` is non-zero, creates an interval that will regenerate
   * noise by updating `baseFrequency
   */
  React.useEffect(() => {
    if (!fps) return
    const intervalHandle = setInterval(() => setBaseFrequencyJitter(random.float(0.001, 0.5)), 1000 / fps)
    return () => clearInterval(intervalHandle)
  }, [])

  if (isSafari) return null

  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${finalWidth} ${finalHeight}`}
      ref={svgRef}
      style={props.style}
      className={props.className}
    >
      <filter id="noiseFilter">
        <feTurbulence
          type={type}
          baseFrequency={baseFrequency.toPrecision(6)}
          numOctaves={numOctaves}
          stitchTiles="stitch"
          seed={Math.random()}
        />
      </filter>
      <rect
        width="100%"
        height="100%"
        filter="url(#noiseFilter)"
      />
    </svg>
  )
}