import LocalForage from 'localforage'

type LocalForageOptions = Parameters<typeof LocalForage['createInstance']>[0];

const globalConfig: LocalForageOptions = {
  driver: LocalForage.LOCALSTORAGE,
  name: location.hostname,
  version: 1
}

/**
 * Local storage interface.
 */
const globalStore = LocalForage.createInstance(globalConfig)

/**
 * Override our store's default `createInstance` method with one that merges
 * provided options with the global defaults, allowing consumers to create
 * scoped stores without having to provide every option.
 */
globalStore.createInstance = (config: LocalForageOptions) => {
  return LocalForage.createInstance({
    ...globalConfig,
    ...config
  })
}

export default globalStore