import { Chalk, type ChalkInstance } from 'chalk'
import {
  createConsola,
  LogLevels,
  type LogType,
  type ConsolaOptions,
  type ConsolaInstance
} from 'consola'
import ms from 'ms'
// import pWaitFor from 'p-wait-for'

import globalStore from 'lib/storage'

/**
 * @private
 *
 * Used to signal that we are still awaiting an asynchronous value.
 */
// const VALUE_PENDING = Symbol('ValuePending')

/**
 * @private
 *
 * If in a Node environment, returns the value of the indicated environment
 * variable. Otherwise, returns `undefined`.
 */
function safelyGetEnvironmentVariable<T = string>(varName: string): T | undefined {
  // eslint-disable-next-line no-undef
  if (typeof process !== 'object' || typeof process.env !== 'object') return
  // eslint-disable-next-line no-undef
  if (!Reflect.has(process.env, varName)) return
  // eslint-disable-next-line no-undef
  return Reflect.get(process.env, varName) as T
}

/**
 * @private
 *
 * Predicate that always returns true.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noOpPredicate = (testScope: string) => true

/**
 * @private
 *
 * Provided a valid DEBUG environment variable, returns a predicate that accepts
 * a debug scope and returns `true` if that scope's log messages should be
 * allowed according to the expression.
 *
 * If an empty string or any non-string value is provided as an expression, the
 * resulting predicate will always return `true`.
 *
 * See: https://www.npmjs.com/package/debug
 */
function createScopeMatcher(debugExpression?: any) {
  if (typeof debugExpression !== 'string' || debugExpression === '') return noOpPredicate

  const rawStatements = debugExpression.split(/,\s*/g)
  const rawSegments = rawStatements.flatMap(s => s.split(':'))
  if (rawSegments.includes('')) return noOpPredicate

  const statements = rawStatements.map(s => s.replace('*', String.raw`[^\s]+`))
  const allowPatterns = statements.filter(s => !s.startsWith('-')).map(s => new RegExp(`^${s}$`))
  const denyPatterns = statements.filter(s => s.startsWith('-')).map(s => new RegExp(`^${s.replaceAll(/^-/g, '')}$`))

  const isAllowed = (input: string) => allowPatterns.some(p => input.match(p))
  const isDenied = (input: string) => denyPatterns.some(p => input.match(p))

  return (testScope: string) => isAllowed(testScope) && !isDenied(testScope)
}

/**
 * @private
 *
 * Creates an object that can be used as a chronograph. After being started, it
 * can be placed directly into interpolated strings to print its current value.
 */
function createChronograph() {
  let state: 'running' | 'paused' = 'paused'
  let segmentStartTime: number
  let msAccumulated = 0

  return {
    get value() {
      return state === 'paused' ? msAccumulated : Date.now() - segmentStartTime + msAccumulated
    },
    toNumber: () => {
      return state === 'paused' ? msAccumulated : Date.now() - segmentStartTime + msAccumulated
    },
    toString: () => {
      return ms(state === 'paused' ? msAccumulated : Date.now() - segmentStartTime + msAccumulated)
    },
    start: () => {
      if (state === 'running') return
      segmentStartTime = Date.now()
      state = 'running'
    },
    pause: () => {
      if (state === 'paused') return
      const segmentRunTime = Date.now() - segmentStartTime
      msAccumulated += segmentRunTime
      state = 'paused'
    },
    reset: () => {
      msAccumulated = 0
    },
    format: ms
  }
}

/**
 * Options accepted by `createLogger`. Accepts all valid Consola options.
 */
export interface EnhancedConsolaOptions extends Omit<ConsolaOptions, 'level'> {
  /**
   * Optional prefix that will appear before log messages.
   */
  prefix?: string | undefined | ((chalk: ChalkInstance) => string | undefined)
  /**
   * Log level. If this value is a Promise, log messages will be paused
   * until it is resolved.
   *
   * In Node, defaults to the LOG_LEVEL environment variable.
   */
  level: LogType | null | undefined | Promise<LogType | null | undefined>
  /**
   * Value to use for determining what debug scopes should be allowed or denied.
   *
   * In Node, defaults to the DEBUG environment variable.
   *
   * @example 'http:server:connect,-http:server:disconnect,http:logger:*'
   * @see https://github.com/debug-js/debug
   */
  debugExpression?: string | undefined | Promise<string | undefined>
  /**
   * Optional debug scope for this logger. If set, any messages issued at the
   * debug level will only be logged if this scope is allowed according to the
   * value of `debugExpression`.
   *
   * @example 'http:server'
   * @see https://github.com/debug-js/debug
   */
  debugScope?: string | undefined
}

/**
 * Value returned from `createLogger`.
 */
export interface EnhancedConsola extends Omit<ConsolaInstance, 'create'> {
  /**
   * Chalk instance that can be used to style log messages.
   *
   * See: https://github.com/chalk/chalk
   */
  chalk: ChalkInstance
  /**
   * Creates a "child" logger using the options provided to this logger. The
   * `prefix` option is not inherited. If a `debugScope` is provided, it will be
   * appended to the debug scope of this logger.
   */
  create: (options?: Partial<EnhancedConsolaOptions>) => EnhancedConsola
  /**
   * Returns a chronograph that can be used to track time.
   *
   * @example
   *
   * const timer = log.createChronograph()
   * timer.start()
   * // ... SeVeRaL MoMeNtS LaTeR ...
   * log.info(`It has been: ${timer}`) // 'It has been: 4s'
   */
  chronograph: () => ReturnType<typeof createChronograph>
}

/**
 * Creates and returns an `EnhancedConsola` instance.
 */
export function createLogger(options: Partial<EnhancedConsolaOptions> = {}): EnhancedConsola {
  const { level, debugExpression, debugScope, prefix, ...restOptions } = options

  const enhancedConsola = Object.assign(createConsola({
    level: LogLevels.silent,
    ...restOptions
  }), {
    chalk: new Chalk({ level: 3 }),
    create: (childOptions: Partial<EnhancedConsolaOptions> = {}): EnhancedConsola => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { prefix: parentPrefix, ...parentOptions } = options
      const mergedOptions = { ...parentOptions, ...childOptions }

      if (options.debugScope || childOptions.debugScope) {
        mergedOptions.debugScope = options.debugScope && childOptions.debugScope
          ? [options.debugScope, childOptions.debugScope].filter(Boolean).join(':')
          : childOptions.debugScope ?? options.debugScope ?? ''
      }

      return createLogger(mergedOptions)
    },
    chronograph: createChronograph
  })

  // If we received an async value as our `level` option, wait for it to resolve
  // then set the logger to that level, if it is valid. Otherwise, set the level
  // to the value of the LOG_LEVEL environment variable, if valid. Otherwise,
  // set the level to 'info', the default for Consola. Then, flush all log
  // messages that may have accumulated while we were waiting.
  const logLevelPromise = Promise.resolve(level ?? safelyGetEnvironmentVariable<LogType>('LOG_LEVEL'))
    .then(resolvedLevel => {
      enhancedConsola.level = resolvedLevel && LogLevels[resolvedLevel]
        ? LogLevels[resolvedLevel]
        : LogLevels.info
    })
    .catch(() => {
      enhancedConsola.level = LogLevels.info
    })

  // Asynchronously resolve `debugExpression`.
  const debugExpressionPromise = Promise.resolve(debugExpression ?? safelyGetEnvironmentVariable('DEBUG'))

  // Asynchronously create a predicate to match debug scopes against the current
  // debug expression.
  const isAllowedDebugScopePromise = debugExpressionPromise.then(createScopeMatcher)

  // Compute the prefix to prepend to log messages.
  const resolvedPrefix = typeof prefix === 'function' ? prefix(enhancedConsola.chalk) : prefix

  // Decorate log methods.
  Object.keys(LogLevels).forEach(logLevel => {
    const originalMethod = Reflect.get(enhancedConsola, logLevel)
    if (typeof originalMethod !== 'function') return

    Reflect.set(enhancedConsola, logLevel, async (...args: Array<any>) => {
      const [
        isAllowedDebugScope,
        debugExpression
      ] = await Promise.all([
        isAllowedDebugScopePromise,
        debugExpressionPromise,
        logLevelPromise
      ])

      if (debugExpression && debugScope && ['debug', 'trace'].includes(logLevel)) {
        // Skip debug messages unless the logger's debug scope is allowed.
        if (!isAllowedDebugScope(debugScope ?? '')) return
        // Otherwise, prepend the configured debug scope to arguments.
        args.unshift(enhancedConsola.chalk.magenta.bold(debugScope))
      }

      // Prepend the configured prefix to arguments.
      if (resolvedPrefix) args.unshift(resolvedPrefix)

      // Invoke the original method.
      Reflect.apply(originalMethod, enhancedConsola, args)
    })
  })

  return enhancedConsola
}

// -----------------------------------------------------------------------------

export const store = globalStore.createInstance({ storeName: 'log' })

const logger = createLogger({
  level: store.getItem<LogType>('level')
})

// interface ConsolaOptions {
//   reporters: ConsolaReporter[];
//   types: Record<LogType, InputLogObject>;
//   level: LogLevel;
//   defaults: InputLogObject;
//   throttle: number;
//   throttleMin: number;
//   stdout?: NodeJS.WriteStream;
//   stderr?: NodeJS.WriteStream;
//   mockFn?: (type: LogType, defaults: InputLogObject) => (...args: any) => void;
//   prompt?: typeof prompt | undefined;
//   formatOptions: FormatOptions;
// }

// reporters: logger.setReporters
// level: logger.level

// types: NOPE
// defaults: NOPE
// throttle: NOPE
// throttleMin: NOPE
// stdout: NOPE
// stderr: NOPE
// mockFn: NOPE
// prompt: IDK?

export default logger