import ms from 'ms'
import React from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

import logger from 'lib/log'

/**
 * @private
 *
 * Scoped logger.
 */
const log = logger.create({ prefix: '💁🏻‍♂️ •' })

/**
 * Props accepted by `ServiceWorkerContext`.
 */
export interface ServiceWorkerContextProps extends React.PropsWithChildren {
  /**
   * How often to poll for updates.
   *
   * @default 10 minutes.
   */
  updateInterval?: number

  /**
   * If `true`, when an update is available, a `beforeunload` event listener
   * will be added to `window` that will call `updateServiceWorker`, allowing
   * the user to perform a _hard_ refresh to install the new service worker and
   * its updates.
   *
   * Note: It is not possible to allow this behavior to work with a normal
   * refresh of the page due to how service workers are implemented.
   *
   * @default false
   */
  updateOnUnload?: boolean
}

/**
 * Value provided by `ServiceWorkerContext`.
 */
export interface ServiceWorkerContextValue {
  /**
   * Set to `true` when a service worker has been installed and all assets have
   * been cached, making the application ready to function offline.
   */
  offlineReady: boolean

  /**
   * Set to `true` when there is a new service worker available.
   */
  needRefresh: boolean

  /**
   * The context will set `needRefresh` to `true` when a new service worker is
   * available. This dispatcher can be used to set it to `false` if the user
   * wants to dismiss an update. This allows `needRefresh` to be used to control
   * whether to display an update prompt or similar UI.
   */
  setNeedRefresh: React.Dispatch<React.SetStateAction<boolean>>

  /**
   * Installs a new service worker and reloads the window to allow it to take
   * control.
   */
  updateServiceWorker: () => Promise<void>
}

const ServiceWorkerContext = React.createContext<ServiceWorkerContextValue>({
  offlineReady: false,
  needRefresh: false,
  setNeedRefresh: () => {/* Empty function. */},
  updateServiceWorker: () => Promise.resolve()
})

function ServiceWorkerProviderProd(props: ServiceWorkerContextProps) {
  const {
    children,
    updateInterval,
    updateOnUnload
  } = props

  const {
    offlineReady: [offlineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW: (serviceWorkerUrl, registration) => {
      if (registration) {
        log.debug('Service worker registered:', registration)

        setInterval(() => {
          if (registration.installing) return
          if (!navigator.onLine) return

          void fetch(serviceWorkerUrl, {
            cache: 'no-store',
            headers: {
              'cache': 'no-store',
              'cache-control': 'no-cache'
            }
          }).then(res => {
            if (res.status !== 200) throw new Error('Error updating service worker.', { cause: res })
            return registration.update()
          }).then(() => {
            log.info('Update check complete.')
          }).catch((err: Error) => {
            // This can happen when an old service worker checks for assets on
            // the server that are no longer present because a new version is
            // available. On the next update attempt, the new service worker
            // will be discovered and an update will be available.
            if (err.message.includes('The script has an unsupported MIME type (\'text/html\')')) return

            log.error('Error checking for updates:', err.message)
          })
        }, updateInterval ?? ms('10 minutes'))
      } else {
        log.warn('Did not receive a registration.')
      }
    },
    onRegisterError: (error: Error) => {
      log.error('Error during registration:', error)
    },
    onNeedRefresh: () => {
      if (updateOnUnload) {
        log.success('New version available. Reload to update.')
        void updateServiceWorker()
      } else {
        log.success('New version available.')
      }
    }
  })

  return (
    <ServiceWorkerContext.Provider
      value={{
        offlineReady,
        needRefresh,
        setNeedRefresh,
        updateServiceWorker
      }}
    >
      {children}
    </ServiceWorkerContext.Provider>
  )
}

function ServiceWorkerProviderDev(props: ServiceWorkerContextProps) {
  const { children } = props
  return children
}

export function ServiceWorkerProvider(props: ServiceWorkerContextProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (import.meta.env.DEV) return <ServiceWorkerProviderDev {...props} />
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ServiceWorkerProviderProd {...props} />
}

export default ServiceWorkerContext