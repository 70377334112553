import { isMobile } from '@darkobits/tsx/lib/runtime'

import { COLOR } from 'etc/constants'
import logger from 'lib/log'

import type { ISourceOptions } from '@tsparticles/engine'

const log = logger.create({ prefix: '✨ •' })

/**
 * With a density factor of 512, this will render about 600 particles in a full
 * screen browser on a 16" MacBook Pro and about 180 particles on an iPhone
 * 11. More importantly, it ensures that the particle density (re: appearance)
 * remains consistent across different device sizes and if/when the browser
 * window is resized.
 */
function getNumParticles(densityFactor: number) {
  const { devicePixelRatio } = globalThis
  const numPixels = screen.width * screen.height
  const density = (isMobile() ? devicePixelRatio : 1) * (densityFactor / 2_600_000)
  const numParticles = Math.floor(density * numPixels)

  log.debug(`Rendering ${numParticles} particles.`)

  return numParticles
}

// ------ Particles Configuration ----------------------------------------------

interface ParticlesConfigOptions {
  fps?: number
}

export function getParticlesConfig(opts: ParticlesConfigOptions = {}) {
  const config: ISourceOptions = {
    autoPlay: true,
    pauseOnBlur: true,
    detectRetina: true,
    fpsLimit: opts.fps ?? 60,
    // Previously, this is needed to prevent flickering in Safari. We now have
    // it disabled so that the <canvas> element is easier to wrangle. The
    // flicker issue does not seem to have re-appeared in Safari.
    fullScreen: false,
    particles: {
      // 512 -> 406
      number: {
        value: getNumParticles(isMobile() ? 320 : 320)
      },
      color: { value: COLOR.PRIMARY },
      shape: { type: 'circle' },
      opacity: {
        value: { min: 0.32, max: 0.64 },
        animation: {
          enable: true,
          mode: 'random',
          startValue: 'random',
          speed: 0.6,
          sync: false
        }
      },
      size: {
        value: { min: 0.9, max: 1.4 }
      },
      links: {
        enable: true,
        // distance: 320,
        // This provides better performance in Safari.
        distance: isMobile() ? 128 : 176,
        color: COLOR.PRIMARY,
        opacity: isMobile() ? 0.1 : 0.1,
        width: 0.5
      },
      move: {
        enable: true,
        speed: { min: 0.08, max: 0.16 },
        direction: 'none',
        random: true,
        straight: false,
        outModes: 'out',
        // bounce: false, // DEPRECATED?
        attract: {
          enable: false,
          rotate: {
            x: 600,
            y: 1200
          }
        }
      }
    },
    interactivity: {
      detectsOn: 'canvas',
      events:{
        resize: {
          enable: true
          // delay: ??? NEW
        },
        onHover: {
          enable: true,
          mode: 'grab'
        },
        onClick: {
          enable: true,
          mode: 'push'
        }
      },
      modes: {
        grab: {
          distance: 96
        },
        push: {
          quantity: 1
        }
      }
    }
  }

  // Possibly deprecated in v3, otherwise not documented.
  // config.interactivity.modes = {
  //   grab: {
  //     distance: 96,
  //     line_linked: {
  //       color: COLOR.PRIMARY,
  //       opacity: 0.2
  //     }
  //   },
  //   bubble: {
  //     distance: 128,
  //     size: 2,
  //     duration: 2,
  //     opacity: 1
  //   },
  //   repulse: {
  //     distance: 200,
  //     duration: 0.4
  //   },
  //   push: { particles_nb: 1 },
  //   remove: { particles_nb: 2 }
  // };

  return config
}