import { isMobile } from '@darkobits/tsx/lib/runtime'
import { loadAll } from '@tsparticles/all'
import { initParticlesEngine } from '@tsparticles/react'
import fScreen from 'fscreen'
import pDebounce from 'p-debounce'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { useAsyncEffect } from 'use-async-effect'

import SmartSuspense from 'components/SmartSuspense'
import { getParticlesConfig } from 'etc/particles-config'

import classes from './Particles.css'

/**
 * Lazy-load this so that we don't block rendering of other content on slow
 * clients.
 */
const ReactParticles = React.lazy(async () => import('@tsparticles/react'))

export const Particles = React.memo(() => {
  const [initialized, setInitialized] = React.useState(false)
  const [opacity, setOpacity] = React.useState(0)
  const [fps, setFps] = React.useState(document.fullscreenElement ? 60 : 24)
  const particlesConfig = React.useMemo(() => getParticlesConfig({ fps }), [fps])

  /**
   * [Effect] Waits for the particles engine to initialize, then sets the
   * component's state to initialized.
   */
  useAsyncEffect(async isMounted => {
    await initParticlesEngine(loadAll)
    if (!isMounted()) return
    setInitialized(true)
  }, [])

  /**
   * [Effect] Registers an event handler to adjust the FPS based on the current
   * fullscreen state.
   */
  React.useEffect(() => {
    const onFullscreenChange = () => setFps(document.fullscreenElement ? 60 : 24)
    fScreen.addEventListener('fullscreenchange', onFullscreenChange)
    return () => fScreen.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [])

  /**
   * [Callback] Reveals particles after they have initialized. This function is
   * debounced because ts-particles will call this init function multiple times
   * in rapid succession, and we only want to call it once.
   */
  const onLoadedDebounced = React.useCallback(pDebounce(() => {
    setOpacity(isMobile() ? 0.72 : 1)
    return Promise.resolve()
  }, 1000), [])

  if (initialized) return (
    <SmartSuspense>
      <div
        aria-hidden="true"
        className={twMerge('h-full', classes.particles)}
        style={{ opacity }}
      >
        <ReactParticles
          className="h-full"
          particlesLoaded={onLoadedDebounced}
          options={particlesConfig}
        />
      </div>
    </SmartSuspense>
  )
})