import 'etc/tailwind.css'
import 'etc/global-styles.css'

import { render } from '@darkobits/tsx/lib/runtime'

import App from 'components/App'
import 'etc/prevent-overscroll'
import 'etc/register-keyboard-shortcuts'
import log from 'lib/log'

log.info('🔖 •', import.meta.env.GIT_DESC)

render('#root', <App />)