
type ResetOptions = Partial<Pick<CreateTimerOptions, 'start' | 'duration'>>

interface CreateTimerOptions {
  duration: number
  start: boolean
  onPause?: (timeRemaining: number) => (void | Promise<void>)
  onResume?: (timeRemaining: number) => (void | Promise<void>)
  onCancel?: (timeRemaining: number) => (void | Promise<void>)
  onEnd?: () => (void | Promise<void>)
}

interface Timer {
  /**
   * Starts the time.
   */
  start: () => void
  /**
   * Pauses the timer.
   */
  pause: () => void
  /**
   * Resets the timer to the provided `duration` and optionally restarts it.
   */
  reset: (opts?: Partial<ResetOptions>) => void
  /**
   * Cancels the timer. A canceled timer cannot be reset or restarted. The timer
   * will invoke its `onCancel` callback, after which no further callbacks will
   * be invoked.
   */
  cancel: () => void
  timeRemaining: number
}

/**
 * Returns a timer object that tracks the amount of time remaining in a provided
 * duration. The timer can be paused, resumed, canceled, and reset.
 */
export function createTimer(opts: CreateTimerOptions) {
  let state: 'RUNNING' | 'PAUSED' | 'CANCELED' | 'EXPIRED' = opts.start ? 'RUNNING' : 'PAUSED'
  let timeRemaining = opts.duration
  let timeoutHandle: NodeJS.Timeout
  let timeoutHandleCreatedAt: number

  const createTimeout = () => {
    if (timeRemaining < 0) {
      state = 'EXPIRED'
      return
    }

    timeoutHandle = setTimeout(() => {
      state = 'EXPIRED'
      if (opts.onEnd) void opts.onEnd()
    }, timeRemaining)

    timeoutHandleCreatedAt = Date.now()
  }

  if (state === 'RUNNING') createTimeout()

  const pause = () => {
    if (['PAUSED', 'CANCELED', 'EXPIRED'].includes(state)) return
    clearTimeout(timeoutHandle)
    timeRemaining = timeRemaining - (Date.now() - timeoutHandleCreatedAt)
    state = 'PAUSED'
    if (opts.onPause) void opts.onPause(timeRemaining)
  }

  const start = () => {
    if (['RUNNING', 'CANCELED', 'EXPIRED'].includes(state)) return
    createTimeout()
    state = 'RUNNING'
    if (opts.onResume) void opts.onResume(timeRemaining)
  }

  const reset = (opts: Partial<ResetOptions> = {}) => {
    if (state === 'CANCELED') return
    pause()
    if (opts.duration) timeRemaining = opts.duration
    if (opts.start) start()
  }

  const cancel = () => {
    if (['CANCELED', 'EXPIRED'].includes(state)) return
    clearTimeout(timeoutHandle)
    state = 'CANCELED'
    if (opts.onCancel) void opts.onCancel(timeRemaining)
  }

  const timer: Timer = {
    pause,
    start,
    reset,
    cancel,
    get timeRemaining() {
      return timeRemaining
    }
  }

  return timer
}