const body: HTMLElement = document.body
const IS_SCROLLER = Symbol('IS_SCROLLER')

body.addEventListener('touchstart', () => {
  const top = body.scrollTop
  const totalScroll = body.scrollHeight
  const currentScroll = top + body.offsetHeight

  if (top === 0) {
    body.scrollTop = 1
  } else if (currentScroll === totalScroll) {
    body.scrollTop = top - 1
  }
})

body.addEventListener('touchmove', event => {
  if (body.offsetHeight < body.scrollHeight) {
    Reflect.set(event, IS_SCROLLER, true)
  }
})

body.addEventListener('touchmove', event => {
  if (!Reflect.get(event, IS_SCROLLER)) {
    event.preventDefault()
  }
}, {passive: false})

// Prevents error TS1208.
export default {}