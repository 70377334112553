import React from 'react'

/**
 * Returns a boolean indicating whether the current tab is active and/or
 * visible.
 */
export function useIsTabActive() {
  const [isTabActive, setIsTabActive] = React.useState(true)

  const handleVisibilityChange = React.useCallback(() => {
    setIsTabActive(document.visibilityState === 'visible')
  }, [])

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isTabActive
}