import { prefetchImage } from '@darkobits/react-kit/prefetch-image'
import React from 'react'

/**
 * Provided a list of image sources, returns a boolean that will be `true` once
 * all images have finished loading.
 */
export default function useWaitForImages(sources: Array<string>) {
  const [imagesReady, setImagesReady] = React.useState(false)
  const [loadedSources, setLoadedSources] = React.useState<Set<string>>(new Set())

  React.useEffect(() => {
    // Only run the effect if there is an incoming source we haven't seen.
    const sourcesToLoad = sources.filter(src => !loadedSources.has(src))
    if (sourcesToLoad.length === 0) return

    setImagesReady(false)

    void Promise.allSettled(sourcesToLoad.map(source => prefetchImage(source))).then(() => {
      setLoadedSources(new Set([...loadedSources, ...sourcesToLoad]))
      setImagesReady(true)
    })
  }, [sources])

  return imagesReady
}